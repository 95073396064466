<script>
export default {
  locales: {
    pt: {
      'My Investment': 'Meu Investimento',
      'My Profit': 'Meu Rendimento',
      'My Team': 'Minha Equipe',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    },
    es: {
      'My Investment': 'Mi Inversion',
      'My Profit': 'Mi Rendimiento',
      'My Team': 'Mi Equipo',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card bg-light text-dark">
    <div class="card-body py-3">
      <div v-if="type == 'center'" class="text-center">
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-success rounded text-dark px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-danger rounded text-dark px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
      </div>
      <div v-else>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-dark font-weight-bold d-flex">
          <div class="flex-fill">
            <span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span>
          </div>
          <div style="margin-top: -15px;">
            <router-link
              tag="a"
              :to="'/tv/subscriber'"
              class="btn btn-default btn-sm font-size-15"
            >
              <span class="notranslate">ATIVAR</span>
            </router-link>
          </div>
        </h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="title == 'Usuário'" class="mb-0 notranslate text-dark font-weight-bold">
          <span>{{ value }}</span>
          <button
            class="btn btn-link p-0 m-0 align-bottom"
            v-clipboard:copy="value"
            v-on:click="$noty.success('O usuário foi copiado.')"
          >
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </h5>
        <h5 v-else-if="title == 'Senha'" class="mb-0 notranslate text-dark font-weight-bold">
          <span>{{ value }}</span>
          <button
            class="btn btn-link p-0 m-0 align-bottom"
            v-clipboard:copy="value"
            v-on:click="$noty.success('A senha foi copiada.')"
          >
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  line-height: 20px;
}
.card-body button {
  position: absolute;
  right: 15px;
  bottom: 10px;
}
.bg {
  background-color: #e0e0e0;
}
</style>